'use strict';

angular.module('windmanagerApp')
  .directive('addUser', function () {
    return {
      templateUrl: 'components/directives/user/addUser/addUser.html',
      restrict: 'EA',
      controller: 'AddEditUserCtrl',
      controllerAs: 'addUser',
      link: function (scope, element, attrs) {
      }
    };
  });
